<template>
<div>
    <div class="container_custom">

        <!-- Home -->
        <div class="home">
            <!-- Home Slider -->
            <div class="home_slider_container">
                <div class="owl-carousel owl-theme home_slider">
                    <!-- Slide -->
                    <div class="slide">
                        <div class="background_image" style="background-image:url(img/background.jpg)"></div>
                        <div class="home_container">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-8">
                                        <div class="home_content text-left">
                                            <div class="home_title">
                                                <h1>Acelera tu preparación, estés donde estés.</h1>
                                                <p class="lead_title">Estudia cualquier tema, en cualquier momento.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Courses -->
        <div class="categories ">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="categories_container d-flex flex-md-row flex-column align-items-start justify-content-start">
                            <!-- Category -->
                            <div class="category text-center">
                                <router-link :to="{ name: 'Courses' }">
                                    <div class="d-flex flex-md-column flex-row align-items-md-center align-items-md-start align-items-center justify-content-start">
                                        <div class="cat_icon"><img src="svg/icon/peru.svg" class="svg" alt="Curso Historia del Perú" title="Curso Historia del Perú">
                                        </div>
                                        <div class="cat_title">Historia</div>
                                    </div>
                                </router-link>
                            </div>

                            <!-- Category -->
                            <div class="category text-center">

                                <router-link :to="{ name: 'Courses' }">
                                    <div class="d-flex flex-md-column flex-row align-items-md-center align-items-md-start align-items-center justify-content-start">
                                        <div class="cat_icon"><img src="svg/icon/literatura.svg" class="svg" alt="Curso Literatura" title="Curso Literatura">
                                        </div>
                                        <div class="cat_title">Literatura</div>
                                    </div>
                                </router-link>
                            </div>

                            <!-- Category -->
                            <div class="category text-center">
                                <router-link :to="{ name: 'Courses' }">
                                    <div class="d-flex flex-md-column flex-row align-items-md-center align-items-md-start align-items-center justify-content-start">
                                        <div class="cat_icon"><img src="svg/icon/biologia.svg" class="svg" alt="Curso Biología" title="Curso Biología">
                                        </div>
                                        <div class="cat_title">Biología</div>
                                    </div>
                                </router-link>
                            </div>

                            <!-- Category -->
                            <div class="category text-center">
                                <router-link :to="{ name: 'Courses' }">
                                    <div class="d-flex flex-md-column flex-row align-items-md-center align-items-md-start align-items-center justify-content-start">
                                        <div class="cat_icon"><img src="svg/icon/psicologia.svg" class="svg" alt="Curso Psicología" title="Curso Psicología">
                                        </div>
                                        <div class="cat_title">Psicología</div>
                                    </div>
                                </router-link>
                            </div>

                            <!-- Category -->
                            <div class="category text-center">
                                <router-link :to="{ name: 'Courses' }">
                                    <div class="d-flex flex-md-column flex-row align-items-md-center align-items-md-start align-items-center justify-content-start">
                                        <div class="cat_icon"><img src="svg/icon/mas-cursos.svg" class="svg" alt="Curso Geografía" title="Más cursos">
                                        </div>
                                        <div class="cat_title">Más cursos</div>
                                    </div>
                                </router-link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Speed -->
        <div class="container_custom mtop">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="section_title text-center">
                            <h2>Acelera tu preparación, estés donde estés.</h2>
                        </div>
                        <div class="icon_box_container d-flex flex-row align-items-start justify-content-between flex-wrap">

                            <!-- Icon Box -->
                            <div class="icon_box d-flex flex-column align-items-center justify-content-start text-center">
                                <div class="icon_box_num">01.</div>
                                <div class="icon_box_icon"><img src="svg/icon/simulacros.svg" alt="Image svg" title="Simulacros"></div>
                                <div class="icon_box_title">Simulacros</div>
                                <div class="icon_box_text">
                                    <p class="title_color">Evalúate constantemente.</p>
                                </div>
                            </div>

                            <!-- Icon Box -->
                            <div class="icon_box d-flex flex-column align-items-center justify-content-start text-center">
                                <div class="icon_box_num">02.</div>
                                <div class="icon_box_icon"><img src="svg/icon/practicas.svg" alt="Image svg" title="Prácticas"></div>
                                <div class="icon_box_title">Prácticas</div>
                                <div class="icon_box_text">
                                    <p class="title_color">Repasa el curso que desees.</p>
                                </div>
                            </div>

                            <!-- Icon Box -->
                            <div class="icon_box d-flex flex-column align-items-center justify-content-start text-center">
                                <div class="icon_box_num">03.</div>
                                <div class="icon_box_icon"><img src="svg/icon/examenes.svg" alt="Image svg" title="Exámenes"></div>
                                <div class="icon_box_title">Exámenes</div>
                                <div class="icon_box_text">
                                    <p class="title_color">Aprende de exámenes anteriores.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Obj -->
        <div class="locations container_custom">
            <div class="how">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl text-center"><img class="d2i" src="img/logro.JPG" alt="Logra tus objetivos" title="Logra tus objetivos">
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl- section_title text-center">
                            <h2>Logra tus objetivos</h2>
                            <p class="lead title_color">Refuerza tus conocimientos, controla tu tiempo y obtén mejores resultados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Start Resolver duda -->
        <div class="mtop">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl- section_title text-center">
                        <h2>Una nueva forma de mejorar tu progreso.</h2>
                        <p class="lead title_color">Evaluate, practica y mejora.</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl text-center">
                        <router-link :to="{ name: 'Courses' }" class="btn btn-outline-primary btn-lg mr-4">Iniciar ahora</router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <footer class="footer container_custom">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="footer_container d-flex flex-md-row flex-column align-items-center justify-content-md-start justify-content-center">
                            <div class="copyright">
                                Copyright &copy; {{ year }}
                            </div>
                            <nav class="footer_nav ml-md-auto order-md-2 order-1">
                                <ul class="d-flex flex-row align-items-center justify-content-start">
                                    <li>
                                        <router-link :to="{ name: 'Courses' }">Cursos</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'Contact' }">Contacto</router-link>
                                    </li>
                                    <li><a href="https://bit.ly/dabuho" target="_blank"><i class="fab fa-facebook-square"></i></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            year:''
        }
    },
    created() {
        this.data()
    },
    methods: {
        data() {
            const aa = new Date().getFullYear();
            this.year = aa
        }
    },
}
</script>

<style>
.title_color{
    color:#000000;
}
.mtop {
    padding-top: 90px;
    padding-bottom: 50px;
}
/***********
1. Fonts
***********/

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900');

/*********************************
2. Body and some general stuff
*********************************/

* {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    background: blue;
    color: #a5a5a5;
}

div {
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

ul {
    list-style: none;
    margin-bottom: 0px;
}

p {
    font-family: 'Poppins', sans-serif;
    /*font-size: 26px;*/
    /*line-height: 2.145;*/
    font-weight: 100;
    /*-webkit-font-smoothing: antialiased;*/
    /*-webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;*/
    text-shadow: rgba(0, 0, 0,) 5 5 4px;
}

p a {
    display: inline;
    position: relative;
    color: inherit;
    border-bottom: solid 1px #ffa07f;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

p:last-of-type {
    margin-bottom: 0;
}

a {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

a,
a:hover,
a:visited,
a:active,
a:link {
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

p a:active {
    position: relative;
    color: #FF6347;
}

p a:hover {
    color: #FFFFFF;
    background: #ffa07f;
}

p a:hover::after {
    opacity: 0.2;
}

::selection {
    background: rgba(255, 165, 75, 0.85);
    color: #FFFFFF;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 600;
    line-height: 1.2;
    color: #000000;
}

img {
    max-width: 100%;
}

button:active {
    outline: none;
}

.form-control {
    color: #0077FF;
}

section {
    display: block;
    position: relative;
    box-sizing: border-box;
}

.clear {
    clear: both;
}

.clearfix::before,
.clearfix::after {
    content: "";
    display: table;
}

.clearfix::after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

.trans_200 {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.trans_300 {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.trans_400 {
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
}

.trans_500 {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.fill_height {
    height: 100%;
}

.prlx_parent {
    overflow: hidden;
}

.prlx {
    height: 130% !important;
}

.parallax-window {
    min-height: 400px;
    background: transparent;
}

.parallax_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.background_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.nopadding {
    padding: 0px !important;
}

.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel .owl-item {
    height: 100%;
}

.slide {
    height: 100%;
}

.section_title {
    padding-top: 14px;
}

.section_title h1 {
    font-size: 30px;
}

.section_title h1 span {
    color: #ffb400;
}

.section_title::after {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 34px;
    height: 2px;
    background: #0077FF;
    content: '';
}

.container_custom .container {
    max-width: 1600px;
}

.button {
    width: 196px;
    height: 57px;
    background: #ffb400;
    border-radius: 5px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.button a {
    display: block;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 57px;
}

.button:hover {
    background: #FFFFFF;
}
/*********************************
5. Home
*********************************/

.home {
    height: 899px;
    /*background: #360f64;*/
    overflow: hidden;
}

.home_slider_container {
    width: 100%;
    height: 100%;
}

.home_slider_dots {
    position: absolute;
    top: 44%;
    left: 53px;
    z-index: 1;
}

.home_slider .owl-dots {
    display: none;
}

.home_slider_custom_dot {
    position: relative;
    width: 100%;
    text-align: center;
    background: transparent;
    line-height: 35px;
    font-size: 16px;
    font-weight: 300;
    color: #FFFFFF;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.home_slider_custom_dot:hover,
.home_slider_custom_dot.active {
    color: #ffb400;
}

.home_slider_custom_dot:not(:last-child) {
    margin-bottom: 15px;
}

.home_container {
    position: absolute;
    left: 0;
    top: 36.2%;
    width: 100%;
}

.home_title {
    display: inline-block;
}

.home_title h1 {
    font-family: 'Ubuntu', Helvetica, Arial;
    color: #FFFFFF;
    font-size: 72px;
    font-weight: 400;
    line-height: 1.2;
}

.home_title h1 span {
    position: relative;
    line-height: 1.2;
}

.home_title h1 span::after {
    display: block;
    position: absolute;
    top: 22px;
    right: -8px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #0077FF;
    line-height: 27px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    content: '+';
}

.search_form_container {
    margin-top: 36px;
}

.search_form {
    display: block;
}

.search_input {
    display: block;
    width: calc(100% - 214px);
    height: 57px;
    border: none;
    outline: none;
    padding-left: 27px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.search_input::-webkit-input-placeholder {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #b9b9b9 !important;
}

.search_input:-moz-placeholder {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #b9b9b9 !important;
}

.search_input::-moz-placeholder {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #b9b9b9 !important;
}

.search_input:-ms-input-placeholder {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #b9b9b9 !important;
}

.search_input::input-placeholder {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #b9b9b9 !important;
}

.search_button {
    width: 196px;
    height: 57px;
    border: none;
    outline: none;
    background: #0077FF;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

/*********************************
6. Categories
*********************************/

.categories {
    z-index: 2;
    background: #FFFFFF;
}

.categories_container {
    border-radius: 5px;
    box-shadow: 0px 32px 114px rgba(0, 0, 0, 0.35);
    margin-top: -132px;
    background: #FFFFFF;
}

.category {
    width: 20%;
}

.category:not(:last-child)::after {
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -1px;
    width: 2px;
    height: 50%;
    background: #e1ecf4;
    content: '';
}

.category a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 36px;
    padding-bottom: 57px;
}

.cat_icon {
    width: 70px;
    height: 70px;
}

.cat_icon img {
    max-height: 100%;
}

.cat_icon svg {
    max-width: 100%;
    max-height: 100%;
}

.svg path,
.svg rect,
.svg polygon {
    fill: #c7d6db;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.category:hover .svg path,
.category:hover .svg rect,
.category:hover .svg polygon {
    fill: #0077FF;
}

.cat_title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    line-height: 0.75;
    margin-top: 26px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.category:hover .cat_title {
    color: #0077FF;
}

/*********************************
7. Locations
*********************************/

.location {
    width: calc((100% - 100px) / 5);
    border: solid 2px transparent;
    margin-bottom: 25px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.location:hover {
    border-color: #006DF0;
    box-shadow: 0px 35px 43px rgba(0, 0, 0, 0.35);
}

/*********************************
9. How it works
*********************************/

.how {
    background: #ffffff;
    padding-top: 95px;
    padding-bottom: 95px;
}

.icon_box_container {
    margin-top: 86px;
}

.icon_box {
    width: calc((100% - 106px) / 3);
    background: #FFFFFF;
    padding-top: 61px;
    padding-bottom: 66px;
    padding-left: 40px;
    padding-right: 40px;
    border: solid 2px #FFFFFF;
    margin-bottom: 53px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}

.icon_box:hover {
    border-color: #006DF0;
    box-shadow: 0px 35px 43px rgba(0, 0, 0, 0.35);
}

.icon_box_icon {
    width: 206px;
    height: 206px;
}

.icon_box_icon img {
    position: relative;
    max-height: 100%;
    z-index: 1;
}

.icon_box_icon::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 156px;
    height: 156px;
    border-radius: 50%;
    border: solid 2px #ffb400;
    background: #fff3d6;
    content: '';
    z-index: 0;
}

.icon_box_title {
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    line-height: 1.2;
    margin-top: 38px;
}

.icon_box_text {
    width: 100%;
    margin-top: 17px;
}

.icon_box_num {
    position: absolute;
    top: -23px;
    left: 39px;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background: #006DF0;
    line-height: 62px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
}

/*********************************
14. Footer
*********************************/

.footer {
    background: #FFFFFF;
}

.footer_container {
    height: 79px;
}

.copyright {
    font-size: 12px;
    font-weight: 400;
    color: #b0b0b0;
}

.footer_nav ul li:not(:last-of-type) {
    margin-right: 51px;
}

.footer_nav ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #b0b0b0;
}

.footer_nav ul li a:hover,
.footer_nav ul li.active a {
    color: #006DF0;
}

/**** Responsive */

/************
1. 1620px
************/

@media only screen and (max-width: 1620px) {
    .container_custom .container {
        max-width: 1400px;
    }
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px) {
    .container_custom .container {
        max-width: 1170px;
    }

    .icon_box_title {
        max-width: 200px;
    }
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px) {}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px) {
    .main_nav ul li:not(:last-of-type) {
        margin-right: 25px;
    }

    .add_listing {
        margin-left: 30px;
    }

    .log_reg {
        margin-left: 20px;
    }
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px) {
    .container_custom .container {
        max-width: 960px;
    }

    .location {
        width: calc((100% - 25px) / 2);
    }

    .icon_box {
        width: calc((100% - 60px) / 3);
        padding-left: 30px;
        padding-right: 30px;
    }

    .icon_box_icon {
        width: 136px;
        height: 136px;
    }

    .icon_box_icon::before {
        width: 106px;
        height: 106px;
    }
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px) {}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
    .container_custom .container {
        max-width: 720px;
    }

    .main_nav {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .search_input {
        width: calc(100% - 170px);
    }

    .search_button {
        width: 152px;
    }

    .cat_icon {
        width: 50px;
        height: 50px;
    }

    .grid_container {
        left: auto;
        width: 100%;
    }

    .grid-item {
        padding-left: 0;
        padding-right: 0;
    }

    .grid_large {
        width: 100%;
    }

    .grid_medium {
        width: 100%;
    }

    .grid_small {
        width: 100%;
    }

    .icon_box {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }

    .icon_box_title {
        max-width: none;
    }

    .cta_title h1 {
        font-size: 48px;
    }

    .cta_text p {
        font-size: 16px;
    }

    .cta_2 {
        padding-bottom: 80px;
    }

    .cta_2_content {
        padding-right: 0px;
        padding-top: 127px;
        padding-bottom: 27px;
    }

    .cta_2_image {
        height: 500px;
    }

    .footer_nav ul li:not(:last-of-type) {
        margin-right: 31px;
    }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {
    .container_custom .container {
        max-width: 540px;
    }

    .add_listing {
        width: 122px;
        height: 38px;
    }

    .add_listing {
        display: none;
    }

    .log_reg ul li a {
        font-size: 14px;
    }

    .menu_link {
        display: block;
    }

    .home_slider_dots {
        left: 30px;
    }

    .home_title h1 {
        font-size: 56px;
        font-family: 'Ubuntu', Helvetica, Arial;
    }

    .home_title h1 span::after {
        top: 17px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
    }

    .category {
        width: 100%;
        padding-left: 30px;
    }

    .category a {
        padding-bottom: 40px;
    }

    .category:not(:last-child)::after {
        right: auto;
        top: auto;
        bottom: 0;
        left: 30px;
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
        width: calc(100% - 60px);
        height: 2px;
    }

    .cat_title {
        margin-top: 0;
        margin-left: 30px;
    }

    .location {
        width: 100%;
        background:#ffffff;
    }

    .location_title {
        left: 15%;
        width: 70%;
        height: 40px;
    }

    .location_title>div a {
        font-size: 16px;
        line-height: 40px;
    }

    .cta_title h1 {
        font-size: 36px;
    }

    .cta_text p {
        font-size: 14px;
    }

    .cta_2_title h1 {
        font-size: 40px;
    }

    .footer_container {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .copyright {
        margin-top: 20px;
    }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
    .container_custom .container {
        max-width: 100%;
    }

    .button {
        width: 156px;
        height: 47px;
    }

    .button a {
        font-size: 14px;
        line-height: 47px;
    }

    .header_content {
        height: 90px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .logo a {
        font-size: 20px;
    }

    .dbarra {
        height: 90px;
    }

    .home {
        height: 100vh;
    }

    .home_title h1 {
        font-size: 37px;
        font-family: 'Ubuntu', Helvetica, Arial;
    }
    
    .home_title p {
        font-size: 20px;
        color: #ffffff;
    }

    .home_title h1 span::after {
        top: 8px;
        right: -12px;
    }

    .home_slider_dots {
        display: none;
    }

    .search_button {
        margin-top: 15px;
    }

    .search_input {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .cta_2_title h1 {
        font-size: 36px;
    }

    .cta_2_image {
        height: 400px;
    }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {}

/************
13. 479px
************/

@media only screen and (max-width: 479px) {
    .icon_box {
        padding-left: 30px;
        padding-right: 30px;
    }
}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {}
</style>
